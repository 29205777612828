import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import icon from "images/MP.png";

import { GoldenButton } from "./GoldenButton";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { WalletConnectButton } from "./WalletConnectButton";

export const Congratulations = ({ connects }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/give-away");
  };
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={4}
      marginBottom={5}
    >
      <Grid item xs={4}>
        <img src={icon} width={isMobile ? 100 : 200} alt="MagicPenny" />
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant={isMobile ? "h5" : "h3"}
          textTransform={"uppercase"}
          shadow={5}
          color={"secondary"}
          marginBottom={2}
          sx={{
            position: "relative",
            // display: "inline-block",
            color: "black", // Main text color
            fontWeight: "bold", // Match bold style
            "&::after": {
              content:
                '"Congratulations, you have received a gift of Magic Penny!"',
              position: "absolute",
              left: 3, // Adjust for more shadow offset
              top: 3, // Adjust for more shadow offset
              color: "#ffbf00", // Set to a custom color (e.g., gold) or theme color like secondary.main
              zIndex: -1,
            },
          }}
        >
          Congratulations, you have received a gift of Magic Penny!
        </Typography>
        <Typography variant="h5" marginBottom={2}>
          The coin that unlocks through sharing.
        </Typography>
      </Grid>
    </Grid>
  );
};
