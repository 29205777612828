import React from "react";
import { Box, Container, Typography } from "@mui/material";
import logo from "images/Logo.png";

export const Footer = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      marginY={2}
      marginX={1}
    >
      <Box marginBottom={2}>
        <img src={logo} width={"350"} alt="MagicPenny" />
      </Box>
      <Container>
        <Box marginBottom={2}>
          <Typography textAlign={"center"}>
            Magic Penny has no association with Malvina Reynolds or her creation
            – Magic Penny the song. This token is simply inspired by the spirit
            of giving and kindness we all recognize.
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography textAlign={"center"}>
            Magic Penny is a meme coin with no intrinsic value or expectation of
            financial return. There is no formal team or roadmap. The Magic
            Penny coin is completely useless and for entertainment purposes
            only.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
