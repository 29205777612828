import React from "react";

import { Box, Container, Typography } from "@mui/material";
import { ColoredArea } from "components/ColoredArea";
import { TopBar } from "components/TopBar";
import { Welcome } from "components/Welcome";
import { GiveAwayForm } from "./components/GiveAwayForm";
import { NumberedItem } from "components/NumberedItem";
import { Social } from "pages/Home/components/Social";
import { Footer } from "components/Footer";
import { StarDivider } from "components/StarDivider";
import { Rules } from "pages/Home/components/Rules";
export const GiveAwayPage = () => {
  return (
    <>
      <TopBar color1="#FFDE69" color2="#69F0FF" />
      <Container>
        <Welcome connects={true} />
      </Container>
      <ColoredArea title={"How to give away Magic Penny:"} color={"blue"}>
        <Box marginBottom={5}>
          <Typography
            textAlign={"center"}
            variant="h5"
            fontWeight={100}
            marginBottom={1}
          >
            Like love, Magic Penny isn’t about buying it – it’s about giving it
            away.
          </Typography>
        </Box>
        <Box>
          <NumberedItem number={"1."} text={"Connect your Wallet"} />
          <NumberedItem
            number={"2."}
            text={"Select how much Magic Penny you want to give away"}
          />
          <NumberedItem
            number={"3."}
            text={
              "Enter the wallet you want to give Magic Penny to. The recipient will be responsible for paying minting/gas fees."
            }
          />
          <NumberedItem
            number={"4."}
            text={
              "Sign the transaction in your wallet. This is free - zero gas will be used."
            }
          />
          <NumberedItem
            number={"5."}
            text={
              "Copy the provided sharing link and share it with the recipient of the gift. "
            }
          />
          <NumberedItem
            number={"6."}
            text={
              "Once the tokens have been minted, you will receive your tokens in return. "
            }
          />
        </Box>
      </ColoredArea>
      <GiveAwayForm />

      <ColoredArea
        title={"Give it away and you'll end up having more..."}
        color={"blue"}
      >
        <Box marginBottom={5}>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            Like love, Magic Penny isn’t about buying it – it’s about giving it
            away.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            Every time you give Magic Penny away you’ll get double what you gave
            in return.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            <b>Give 1 away</b> and you’ll <b>get back 2</b>.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            <b>Give 10 away</b> and <b>get back 20</b>.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            You get the idea…
          </Typography>
        </Box>
        <StarDivider />
        <Rules />
      </ColoredArea>
      <Social color={"blue"} />
      <Footer />
    </>
  );
};
