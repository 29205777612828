import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { ColoredArea } from "components/ColoredArea";
import { StarDivider } from "components/StarDivider";
import { TopBar } from "components/TopBar";
import { Welcome } from "components/Welcome";
import { NumberedItem } from "components/NumberedItem";
import { Rules } from "./components/Rules";
import { Roadmap } from "./components/Roadmap";
import { Footer } from "components/Footer";
import { Social } from "./components/Social";

export const Home = () => {
  return (
    <>
      <TopBar color1="#FFDE69" color2="#69FFDC" />
      <Container>
        <Welcome connects={false} />
      </Container>
      <ColoredArea
        title={"Give it away and you'll end up having more..."}
        color={"green"}
      >
        <Box marginBottom={5}>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            Like love, Magic Penny isn’t about buying it – it’s about giving it
            away.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            Every time you give Magic Penny away you’ll get double what you gave
            in return.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            <b>Give 1 away</b> and you’ll <b>get back 2</b>.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            <b>Give 10 away</b> and <b>get back 20</b>.
          </Typography>
          <Typography textAlign={"center"} variant="h5" marginBottom={1}>
            You get the idea…
          </Typography>
        </Box>
        <StarDivider />
        <Rules />
      </ColoredArea>
      <Roadmap />
      <Social color={"green"} />
      <Footer />
    </>
  );
};
