import { Telegram, Twitter, X } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { ColoredArea } from "components/ColoredArea";
import React from "react";
import { Link } from "react-router-dom";

export const Social = ({ color }) => {
  return (
    <ColoredArea color={color} title={"Visit our socials"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        marginX={1}
        marginY={2}
      >
        {/* <Box marginX={5}>
          <IconButton
            sx={{ padding: 2, backgroundColor: "#00C8FF", boxShadow: 20 }}
          >
            <Telegram sx={{ fontSize: 100 }} />
          </IconButton>
        </Box> */}
        <Box marginX={5}>
          <IconButton
            sx={{ padding: 2, backgroundColor: "white", boxShadow: 20 }}
            LinkComponent={Link}
            to="https://x.com/mpny_project"
            target="_blank"
            rel="no_opener no_referrer"
          >
            <X sx={{ fontSize: 100 }} />
          </IconButton>
        </Box>
      </Box>
    </ColoredArea>
  );
};
