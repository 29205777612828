import { Utils } from "alchemy-sdk";
import { ethers } from "ethers";

export const getPermitSignature = async (
  contract,
  signer,
  receiver,
  value,
  deadline
) => {
  // get the current nonce for the signer address
  const nonces = await contract.nonces(signer.address);

  // set the domain parameters
  const domain = {
    name: await contract.name(),
    version: "1",
    chainId: process.env.REACT_APP_BLOCKCHAIN_CHAIN_ID_SIGNATURE,
    verifyingContract: await contract.getAddress(),
  };

  // set the Permit type parameters
  const types = {
    Permit: [
      {
        name: "owner",
        type: "address",
      },
      {
        name: "spender",
        type: "address",
      },
      {
        name: "value",
        type: "uint256",
      },
      {
        name: "nonce",
        type: "uint256",
      },
      {
        name: "deadline",
        type: "uint256",
      },
    ],
  };

  // set the Permit type values
  const values = {
    owner: signer.address,
    spender: receiver,
    value,
    nonce: nonces,
    deadline,
  };
  const signature = await signer.signTypedData(domain, types, values);
  const splitted = Utils.splitSignature(signature);

  const validation = ethers.verifyTypedData(domain, types, values, splitted);

  if (signer.address !== validation) {
    throw new Error("Signature missmatch");
  }

  return splitted;
};
