import { Button, Typography } from "@mui/material";
import React from "react";

export const GoldenButton = ({ text, onClick, ...props }) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      variant="contained"
      style={{
        background: "linear-gradient(90deg, #BF7442, #FBE578, #B25B36)", // Gold gradient
        borderRadius: 25,
        border: "2px solid black", // Black border
        padding: "10px 20px", // Padding to match the style in the image
        boxShadow: "none", // Remove default shadow for a flat look
      }}
      sx={{
        "&:hover": {
          background: "linear-gradient(90deg, #D0915A, #FFE59B, #C46A45)", // Lighter hover effect
          boxShadow: "none",
        },
      }}
    >
      <Typography
        textTransform="uppercase"
        variant="h6"
        fontWeight="bold"
        color="black"
      >
        {text}
      </Typography>
    </Button>
  );
};
