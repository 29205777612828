import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import { ColoredArea } from "components/ColoredArea";
import { TopBar } from "components/TopBar";
import { Congratulations } from "components/Congratulations";
import { useDispatch, useSelector } from "react-redux";
import { hideBackdrop, showBackdrop, showSnackbar } from "state/ui";
import { db } from "configuration/firebase";
import { ethers } from "ethers";
import magicPenny from "utils/MagicPenny.json";
import { Footer } from "components/Footer";
import { NumberedItem } from "components/NumberedItem";
import { WalletConnectButton } from "components/WalletConnectButton";
import { GoldenButton } from "components/GoldenButton";
import { Rules } from "pages/Home/components/Rules";
import { Social } from "pages/Home/components/Social";
import { Success } from "./Success";
import { logError } from "utils/errors";

export const ReceivePage = () => {
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const { connection } = useSelector((state) => state.blockchain);
  const dispatch = useDispatch();
  const id = searchParams.get("id");
  const [docId, setDocId] = useState();
  useEffect(() => {
    if (id) setDocId(id);
  }, [id]);

  const handleClick = async () => {
    try {
      dispatch(showBackdrop());
      const snapshot = await db.collection("approveRequest").doc(docId).get();
      if (!snapshot.exists) {
        dispatch(
          showSnackbar({
            severity: "info",
            message: "There is no valid request at this link.",
          })
        );
      } else {
        const data = snapshot.data();
        const contractAddress =
          process.env.REACT_APP_BLOCKCHAIN_MAGIC_PENNY_ADDRESS;
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(connection.wallet);

        if (!signer) {
          throw new Error(
            "Not a valid signer. Verify your wallet and try again."
          );
        }
        const contract = new ethers.Contract(
          contractAddress,
          magicPenny.abi,
          signer
        );

        if (!contract) {
          throw new Error(
            "Unable to get Magic Penny contract. Verify your connection and try again"
          );
        }

        if (signer.address != data.to) {
          throw new Error(
            "This Give away is not for this wallet. Connect the intended wallet."
          );
        }

        const response = await contract.giveAway(
          data.from,
          data.to,
          data.amount,
          data.deadline,
          data.v,
          data.r,
          data.s
        );
        setSuccess(true);
      }
      dispatch(hideBackdrop());
    } catch (error) {
      logError("HandleReceive", error);
      console.log(error);
      if (error.message.includes("Only receiver can execute.")) {
        dispatch(
          showSnackbar({
            severity: "error",
            message:
              "Verify the connected wallet is valid. Only the receiver can execute this action.",
          })
        );
      } else if (error.message.includes("Give away not available yet")) {
        dispatch(
          showSnackbar({
            severity: "error",
            message:
              "Thw owner can only execute 1 give away per day. Try again later or contact the sender of the Magic Penny.",
          })
        );
      } else if (
        error.message.includes("This Give away is not for this wallet")
      ) {
        dispatch(
          showSnackbar({
            severity: "error",
            message:
              "This Give away is not for this wallet. Connect the intended wallet.",
          })
        );
      } else {
        dispatch(
          showSnackbar({
            severity: "error",
            message:
              "There was an error processing your request. Please try again later.",
          })
        );
      }
      console.log(error);
      dispatch(hideBackdrop());
    } finally {
      dispatch(hideBackdrop());
    }
  };

  return (
    <>
      <TopBar color1="#000000" color2="#FFDE69" />
      <Container>
        <Congratulations />
      </Container>
      <ColoredArea title={"What Happens Now?"} color={"yellow"}>
        <Container>
          <Box marginBottom={2}>
            <NumberedItem
              number={"1."}
              text={
                "You have 24 hours to accept your gift of Magic Penny before it expires."
              }
            />
            <NumberedItem
              number={"2."}
              text={
                "Once you accept your gift, you can immediately start giving away Magic Penny as well."
              }
            />
          </Box>
        </Container>
      </ColoredArea>

      <ColoredArea title={"How To Accept Your Magic Penny?"} color={"yellow"}>
        <Container>
          <Box marginBottom={2}>
            <NumberedItem number={"1."} text={"Connect your wallet"} />
            <NumberedItem
              number={"2."}
              text={"Sign the transaction & that’s it!"}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {connection.wallet ? (
              <GoldenButton text={"Get Magic Coins"} onClick={handleClick} />
            ) : (
              <WalletConnectButton />
            )}
          </Box>
          {success && <Success />}
        </Container>
      </ColoredArea>

      <Rules />
      <Social color={"yellow"} />
      <Footer />
    </>
  );
};
