import { CopyAllRounded } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { showSnackbar } from "state/ui";

export const MagicLink = ({ link }) => {
  const baseLink = process.env.REACT_APP_BASE_SHARING_LINK;
  const dispatch = useDispatch();
  const handleCopy = () => {
    dispatch(
      showSnackbar({
        severity: "success",
        message: "Link copied. Happy sharing!",
      })
    );
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      margin={2}
    >
      <Typography variant="h5" textAlign={"center"} fontWeight={"bold"}>
        Copy Your Magic Link Below:
      </Typography>

      <Box marginY={2} display={"flex"} flexDirection={"row"}>
        <Box>
          <TextField
            variant="standard"
            fullWidth
            value={baseLink + link}
            sx={{ m: 1, width: isMobile ? "30ch" : "60ch", boxShadow: 10 }}
            disabled
          />
        </Box>
        <Box>
          <CopyToClipboard text={baseLink + link}>
            <IconButton onClick={handleCopy}>
              <CopyAllRounded />
            </IconButton>
          </CopyToClipboard>
        </Box>
      </Box>
    </Box>
  );
};
