import React from "react";
import { Box } from "@mui/material";
import stars from "images/Stars.png";

export const StarDivider = () => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <img src={stars} width={50} alt="MagicPenny" />
    </Box>
  );
};
