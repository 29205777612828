import { createSlice } from "@reduxjs/toolkit";

export const blockchainSlice = createSlice({
  name: "blockchain",
  initialState: {
    connection: { wallet: null, network: null },
  },
  reducers: {
    setConnection: (state, action) => {
      state.connection = action.payload;
    },
  },
});

export const { setConnection } = blockchainSlice.actions;

export default blockchainSlice.reducer;
