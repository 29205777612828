import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import bgImageGreen from "images/GreenBackground.png";
import bgImageBlue from "images/BlueBackground.png";
import bgImageYellow from "images/YellowBackground.png";
import { StarDivider } from "./StarDivider";

export const ColoredArea = ({ children, title, color, ...props }) => {
  const [coloredArea, setColoredArea] = useState({
    bgColor: "#69FFDC",
    bgImage: bgImageGreen,
  });
  useEffect(() => {
    switch (color) {
      case "green":
        setColoredArea({ bgColor: "#69FFDC", bgImage: bgImageGreen });
        break;
      case "blue":
        setColoredArea({ bgColor: "#69F0FF", bgImage: bgImageBlue });
        break;
      case "yellow":
        setColoredArea({ bgColor: "#FFDE69", bgImage: bgImageYellow });
        break;
      default:
        setColoredArea({ bgColor: "#69FFDC", bgImage: bgImageGreen });
    }
  }, []);

  return (
    <Box
      width={"100%"}
      bgcolor={coloredArea.bgColor}
      paddingBottom={10}
      paddingTop={3}
      style={{
        backgroundImage: "url(" + coloredArea.bgImage + ")",
        backgroundposition: "left center",
        backgroundRepeat: "repeat-y",
      }}
      {...props}
    >
      <Container>
        <StarDivider />

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginY={3}
        >
          <Typography variant="h3" fontWeight={"bold"} textAlign={"center"}>
            {title}
          </Typography>
        </Box>
        {children}
      </Container>
    </Box>
  );
};
