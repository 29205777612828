import React, { useEffect, useState } from "react";
import logo from "images/MP.png";
import brand from "images/Logo.png";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { GoldenButton } from "./GoldenButton";
import { Telegram, X } from "@mui/icons-material";
import { getReadOnlyContract } from "utils/web3";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { logError } from "utils/errors";

export const TopBar = ({ color1 = "#FFDE69", color2 = "#69FFDC" }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({ holders: 0, holdersLimit: 0 });

  const getWalletCount = async () => {
    try {
      const contract = await getReadOnlyContract();
      const wallets = await contract.methods.holders().call();
      const holdersLimit = await contract.methods.holdersLimit().call();
      setData({
        holders: new Intl.NumberFormat("en-US").format(wallets),
        holdersLimit: new Intl.NumberFormat("en-US").format(holdersLimit),
      });
    } catch (error) {
      logError("GetWalletCount", error);
    }
  };
  useEffect(() => {
    getWalletCount();
  }, []);

  return (
    <Grid width={"100%"} container direction="column">
      <Grid
        item
        width="100%"
        style={{ backgroundColor: color1 }}
        paddingY={0.5}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <img src={logo} width={25} alt="MagicPenny" />
          <Typography
            variant="body"
            fontWeight={"bold"}
            textTransform={"uppercase"}
            color={color1 == "#000000" && "primary"}
            marginLeft={2}
          >
            Magic Penny Wallets {data.holders} / {data.holdersLimit}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        width="100%"
        style={{ backgroundColor: color2 }}
        paddingY={0.5}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item marginLeft={4}>
            <Button href="/">
              <img src={brand} width={150} alt="MagicPenny"></img>
            </Button>
          </Grid>
          <Grid item marginRight={4}>
            {!isMobile && (
              <GoldenButton
                text={"give Magic Penny"}
                onClick={() => navigate("/give-away")}
              />
            )}
            {/* <IconButton>
              <Telegram />
            </IconButton> */}
            <IconButton
              LinkComponent={Link}
              to="https://x.com/mpny_project"
              target="_blank"
              rel="no_opener no_referrer"
            >
              <X />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
