import { Box, Container, Typography } from "@mui/material";
import { GoldenButton } from "components/GoldenButton";
import { StarDivider } from "components/StarDivider";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Roadmap = () => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/give-away");
  };
  return (
    <Container>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        marginY={4}
      >
        <StarDivider />
        <Typography variant="h3" fontWeight={"bold"} marginTop={1}>
          {" "}
          Roadmap
        </Typography>
        <Typography variant="h5" fontWeight={"bold"} marginTop={5}>
          Psyche! There is no roadmap. No tech. No rocket ships.
        </Typography>
        <Typography variant="h5" marginTop={2}>
          The only promise is that holding Magic Penny makes you part of a
          community that believes in the magic of giving and sharing – whether
          that’s love or just our sweet little meme coin.
        </Typography>

        <Box marginY={4}>
          <GoldenButton text={"Give Magic Penny"} onClick={handleOnClick} />
        </Box>
      </Box>
    </Container>
  );
};
