import { Grid, Typography } from "@mui/material";
import React from "react";

export const NumberedItem = ({ number, text }) => {
  return (
    <Grid
      container
      direction={"row"}
      spacing={4}
      justifyContent={"left"}
      alignItems={"center"}
      marginBottom={2}
    >
      <Grid item xs={1}>
        <Typography variant="h2" fontWeight={"bold"}>
          {number}
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h5" fontWeight={"bold"}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
