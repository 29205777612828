import { Box, Container, Typography } from "@mui/material";
import { ColoredArea } from "components/ColoredArea";
import React from "react";

export const Success = () => {
  return (
    <Container>
      <Box marginTop={2}>
        <ColoredArea color={"yellow"} title={"Congratulations! "} padding={2}>
          <Typography variant="h5" textAlign={"center"} marginBottom={2}>
            You’re now part of a community that believes in the magic of giving
            and sharing – whether it’s love for others or just our sweet little
            meme coin.
          </Typography>

          <Typography variant="h5" textAlign={"center"} marginBottom={2}>
            Now get busy giving Magic Penny away to earn more!
          </Typography>
        </ColoredArea>
      </Box>
    </Container>
  );
};
