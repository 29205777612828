import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { GoldenButton } from "components/GoldenButton";
import { StarDivider } from "components/StarDivider";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";

import { db } from "configuration/firebase";
import moment from "moment";
import { getPermitSignature } from "utils/SigningHelper";
import { hideBackdrop, showBackdrop, showSnackbar } from "state/ui";
import { MagicLink } from "./MagicLink";
import { getMagicPennyContract } from "utils/web3";
import { logError } from "utils/errors";

export const GiveAwayForm = () => {
  const { connection } = useSelector((state) => state.blockchain);
  const [link, setLink] = useState();
  const dispatch = useDispatch();
  const [balance, setBalance] = useState(0);
  const [giveAwayData, setGiveAwayData] = useState({
    timestamp: moment().unix(),
    delay: 0,
    giveAways: 0,
  });
  const [formData, setFormData] = useState({
    amount: ethers.formatEther(balance),
    address: "",
    errorAmount: false,
    errorWallet: false,
    submitDisabled: true,
  });

  const verifyValidity = async () => {
    try {
      dispatch(showBackdrop());
      await getTokenBalance();
      await getLastGiveAway();
    } catch (error) {
      logError("verifyValidity", error);
      dispatch(
        showSnackbar({
          severity: "error",
          message: "There was an error verifying your Magic Pennies",
        })
      );
    } finally {
      dispatch(hideBackdrop());
    }
  };

  useEffect(() => {
    if (connection.wallet) {
      verifyValidity();
    }
  }, [connection]);

  useEffect(() => {
    if (balance > 0) {
      setFormData((current) => ({
        ...current,
        amount: ethers.formatEther(balance),
      }));
    }
  }, [balance]);

  const getTokenBalance = async () => {
    const { contract } = await getMagicPennyContract(
      window.ethereum,
      connection.wallet
    );

    const response = await contract.balanceOf(connection.wallet);
    setBalance(response);

    if (response == "0") {
      dispatch(
        showSnackbar({
          severity: "warning",
          message: `You don't own any Magic Penny. Can't give away any.`,
        })
      );
    }
  };

  const getLastGiveAway = async () => {
    const { contract, provider } = await getMagicPennyContract(
      window.ethereum,
      connection.wallet
    );

    const giveAways = await contract.giveAways(connection.wallet);
    const delay = await contract.giveAwayDelay();
    const timestamp = (await provider.getBlock("latest")).timestamp;
    setGiveAwayData({ timestamp: timestamp, delay, giveAways });
    if (timestamp < Number(giveAways) + Number(delay)) {
      dispatch(
        showSnackbar({
          severity: "warning",
          message: `You gave away too soon. You will have to wait ${
            Number(giveAways) + Number(delay) - timestamp
          } seconds`,
        })
      );
    }
  };

  const onAmountChange = (e) => {
    setFormData((current) => ({ ...current, amount: e.target.value }));
    if (e.target.value > ethers.formatEther(balance)) {
      setFormData((current) => ({ ...current, errorAmount: true }));
    } else {
      setFormData((current) => ({ ...current, errorAmount: false }));
    }
  };

  const handleAddressChange = (e) => {
    setFormData((current) => ({ ...current, address: e.target.value }));
    if (ethers.isAddress(e.target.value)) {
      setFormData((current) => ({ ...current, errorWallet: false }));
    } else {
      setFormData((current) => ({ ...current, errorWallet: true }));
    }
  };

  const handleGenerateLink = async () => {
    dispatch(showBackdrop());
    try {
      const { contract, signer } = await getMagicPennyContract(
        window.ethereum,
        connection.wallet
      );
      const deadline = moment().add(1, "day").unix();
      const amount = ethers.parseUnits(formData.amount, "ether");
      const signature = await getPermitSignature(
        contract,
        signer,
        formData.address,
        amount,
        deadline
      );
      const ref = db.collection("approveRequest").doc();

      await ref.set({
        from: signer.address,
        to: formData.address,
        deadline,
        timestamp: moment().unix(),
        id: ref.id,
        r: signature.r,
        s: signature.s,
        v: signature.v,
        amount: ethers.formatUnits(amount, "wei"),
      });

      setLink(`/receive?id=${ref.id}`);
    } catch (error) {
      logError("handleGenerateLink", error);
      dispatch(
        showSnackbar({
          severity: "error",
          message: "There was an error preparing your signature.",
        })
      );
      console.log(error);
    } finally {
      dispatch(hideBackdrop());
    }
  };
  return (
    <Box marginY={5}>
      <Container>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Grid item>
            <StarDivider />
          </Grid>
          <Grid item>
            <Typography variant="h5" textAlign={"center"} fontWeight={"bold"}>
              How much Magic Penny do you want to send?
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              sx={{ m: 1 }}
              fullWidth
              variant="outlined"
              color="secondary"
            >
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                value={formData.amount}
                onChange={onAmountChange}
                label="Amount"
                disabled={!connection.wallet || balance == "0"}
                error={formData.errorAmount}
              />
              <FormHelperText
                id="outlined-weight-helper-text"
                error={formData.errorAmount}
              >
                You have {ethers.formatEther(balance)} Magic Pennies
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h5" textAlign={"center"} fontWeight={"bold"}>
                Where do you want to send Magic Penny (wallet address)
              </Typography>
            </Box>
            <FormControl
              sx={{ m: 1 }}
              fullWidth
              variant="outlined"
              color="secondary"
            >
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">
                    <AccountBalanceWalletIcon />
                  </InputAdornment>
                }
                error={formData.errorWallet}
                label="Wallet Address"
                value={formData.address}
                disabled={!connection.wallet || balance == "0"}
                onChange={handleAddressChange}
              />
              <FormHelperText
                id="outlined-weight-helper-text"
                error={formData.errorWallet}
              >
                Wallet address you are giving away
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <GoldenButton
              text={"Generate Magic Sharing Link"}
              fullWidth
              disabled={
                formData.errorAmount ||
                formData.amount == "" ||
                formData.address == "" ||
                formData.errorWallet ||
                balance == "0" ||
                giveAwayData.timestamp <
                  Number(giveAwayData.giveAways) + Number(giveAwayData.delay)
              }
              onClick={handleGenerateLink}
            />
          </Grid>
        </Grid>

        {link && <MagicLink link={link} />}
      </Container>
    </Box>
  );
};
