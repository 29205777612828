import { Box, Container, Typography } from "@mui/material";
import { NumberedItem } from "components/NumberedItem";
import React from "react";

export const Rules = () => {
  return (
    <Container>
      <Box marginBottom={5} marginTop={1}>
        <Typography variant="h4" fontWeight={"bold"} textAlign={"center"}>
          The rules
        </Typography>
      </Box>

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <NumberedItem
          number={"1."}
          text={
            "Each wallet can only give away Magic Penny once every 24 hours"
          }
        />
        <NumberedItem
          number={"2."}
          text={
            "If the gift is not accepted and minted within 24 hours then the gift will expire and will need to be sent again."
          }
        />
        <NumberedItem
          number={"3."}
          text={
            "When the total number of wallets holding Magic Penny exceeds 10k the double bonus gifting feature is turned off. "
          }
        />
        <NumberedItem
          number={"4."}
          text={
            "If the total number of wallets holding Magic Penny ever goes below 10k wallets, the doubling feature will be turned back on."
          }
        />
      </Box>
    </Container>
  );
};
