import React, { useState } from "react";
import { useSDK } from "@metamask/sdk-react";
import { GoldenButton } from "./GoldenButton";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "state/ui";
import { setConnection } from "state/blockchain";
import { shortenAddress } from "utils/misc";
import { logError } from "utils/errors";

export const WalletConnectButton = () => {
  const { sdk, chainId } = useSDK();
  const { connection } = useSelector((state) => state.blockchain);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("false");

  const handleConnect = async () => {
    setLoading("true");
    try {
      const accounts = await sdk?.connect();
      if (accounts.length === 0) return;
      if (chainId !== process.env.REACT_APP_BLOCKCHAIN_CHAIN_ID) {
        dispatch(
          showSnackbar({
            severity: "warning",
            message: `You are connected to the wrong chain. Connect to ${process.env.REACT_APP_BLOCKCHAIN_NETWORK} and retry.`,
          })
        );
      } else {
        dispatch(
          setConnection({
            wallet: ethers.getAddress(accounts[0]),
            network: chainId,
          })
        );
      }
    } catch (error) {
      logError("Connect Wallet", error )
      dispatch(showSnackbar({ message: error.message, severity: "error" }));
    }
    setLoading("false");
  };
  return (
    <GoldenButton
      text={
        connection.wallet
          ? shortenAddress(connection.wallet)
          : "Connect your wallet "
      }
      onClick={handleConnect}
      loading={loading}
    />
  );
};
